.page__title {


}
.page__subtitle {
  font-family: $sans-font-family;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.25rem;
  margin-bottom: -.25rem;

  color:$primary-color;
  .ccol-1 & { color: $primary-color-v1;}
  .ccol-2 & { color: $primary-color-v2;}
  .ccol-3 & { color: $primary-color-v3;}
  .ccol-4 & { color: $primary-color-v4;}

}