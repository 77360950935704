.siteheader {
  padding:1rem 0;
}

a.sitelogo {
  display: inline-block;
  font-size: .666rem;
  line-height: 1;
  text-transform: uppercase;
  font-weight: $global-weight-bold;
  font-family: $sans-font-family;
  //text-align: right;
  position: absolute;
  color:$body-font-color;

  @include breakpoint(medium) {
    position: static;
  }

  span {
    display: block;
    font-size: 1.5rem;
    //margin-top: -.25em;
    //text-align: left;

  }
}

.siteheader__cto {
  float:right;
  margin: 0;
}

.sitenav {
  //margin-left: rem-calc(-$grid-column-gutter/2);
  //margin-right: rem-calc(-$grid-column-gutter/2);
  @include breakpoint(large) {

  }

  @include breakpoint(medium) {
    float:right;
    margin-left: 0;
    margin-right: .5rem;
  }
}