.menu {
  > li {
    > a {
      color:$body-font-color;
      font-family: $sans-font-family;
      text-transform: uppercase;
      font-size: .875rem;
      &:hover, &:focus {
        color:$black;
      }

      &.active {
        color:$primary-color;

        .ccol-1 & { color: $primary-color-v1;}
        .ccol-2 & { color: $primary-color-v2;}
        .ccol-3 & { color: $primary-color-v3;}
        .ccol-4 & { color: $primary-color-v4;}
      }
    }
  }
}


.subnav {
  background-color: $primary-color;
  .ccol-1 & { background-color: $primary-color-v1;}
  .ccol-2 & { background-color: $primary-color-v2;}
  .ccol-3 & { background-color: $primary-color-v3;}
  .ccol-4 & { background-color: $primary-color-v4;}

}

.tabs--submenu {
  margin-left: rem-calc(-$grid-column-gutter/2);
  margin-right: rem-calc(-$grid-column-gutter/2);
  @include breakpoint(large) {
    margin-left: 0;
    margin-right: 0;
  }
}

.tabs {
  border:none;
}

.tabs-title {
  > a {
    font-weight: $global-weight-bold;
    color:rgba($white,.95);
    @include breakpoint(large) {
      font-size: .875rem;
    }
  }

  > a:not([aria-selected="true"]) {
    &:hover {
      background-color: $tab-item-background-hover;
      color:$white;
    }
  }
  > a[aria-selected="true"] {
      color:$primary-color;
     .ccol-1 & {
        color: $primary-color-v1;
      }
      .ccol-2 & {
        color: $primary-color-v2;
      }
      .ccol-3 & {
        color: $primary-color-v3;
      }
      .ccol-4 & {
        color: $primary-color-v4;
      }

  }



}