.sitebottom {
  padding:4rem 0;
  a {
    color:inherit;
    &:hover, &:focus {
      color:$primary-color;
      .ccol-1 & { color: $primary-color-v1;}
      .ccol-2 & { color: $primary-color-v2;}
      .ccol-3 & { color: $primary-color-v3;}
      .ccol-4 & { color: $primary-color-v4;}

    }
  }
}

.sitefooter {
  font-family: $sans-font-family;
  font-weight: 300;
  padding:1rem 0;
}