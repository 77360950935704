.cardblock {
  margin-top: 2rem;
  margin-bottom:0;
}
.card {
  border-radius: $global-radius/3*2 $global-radius/3*2 0 0;
  margin: 0 0 2rem 0;

}
.card.bgxlight a {
    color:$primary-color-v3;
    //color:inherit;
    //text-decoration: underline;
}

.card__head {
  padding:1rem;
}

.card__foot {
  padding:.5rem 1rem .5rem 1rem;
}
.card__label {
  display: inline-block;
  padding: .333em .25em;
  background-color: $primary-color-v3;
  color:$white;
  font-family: $sans-font-family;
  font-size: .75rem;
  line-height: 1;
  text-transform: uppercase;
  font-weight: $global-weight-bold;
  margin-bottom: .5rem;
}
.card__contact {
  font-size: .875rem;
  font-family: $sans-font-family;
  font-weight: $global-weight-bold;
  margin-bottom: 0;
}
.card__title {
  opacity:1;
  a {
    color:inherit;
  }
}

.card__excerpt {
  font-size: .875rem;
}
a.card__more {
  font-family: $sans-font-family;
  font-size: .875rem;
  line-height: 1;

}

.card__fig {
  margin:0;
  padding:0;
}

.illist {

  margin-top: 1rem;
  margin-bottom: 1rem;
  > li {
   margin-bottom:$paragraph-margin-bottom*1.25 !important;
   h4 {

    opacity: .9;
   }
  }
}