h1, h2, h3, h4, h5, h6 {
  opacity: .9;
  small {
    opacity: .75;
  }
}


// Links
a {
  color: $anchor-color;
  font-weight: $global-weight-bold;
  text-decoration: $anchor-text-decoration;
  line-height: inherit;
  cursor: pointer;

  .ccol-1 & { color: $primary-color-v1;}
  .ccol-2 & { color: $primary-color-v2;}
  .ccol-3 & { color: $primary-color-v3;}
  .ccol-4 & { color: $primary-color-v4;}


  &:hover,
  &:focus {
    color: $anchor-color-hover;
    .ccol-1 & { color: scale-color($primary-color-v1, $lightness: -14%);}
    .ccol-2 & { color: scale-color($primary-color-v2, $lightness: -14%);}
    .ccol-3 & { color: scale-color($primary-color-v3, $lightness: -14%);}
    .ccol-4 & { color: scale-color($primary-color-v4, $lightness: -14%);}

    @if $anchor-text-decoration-hover != $anchor-text-decoration {
      text-decoration: $anchor-text-decoration-hover;
    }
  }

  .bginverse & {
    color:rgba($white,.8);
    &:hover, &:focus {
      color:$white;
    }
  }


}


.lead {
  //margin-bottom: 1.5rem;
}


blockquote {
  @include clearfix();

  font-size: 112.5%;
  // border-radius: 0 $global-radius/8 $global-radius/8 0;

  background-color: rgba($primary-color,.0333);
  border-left:6px solid $primary-color;
  .ccol-1 & { background-color: rgba($primary-color-v1,.0333); border-color: $primary-color-v1;}
  .ccol-2 & { background-color: rgba($primary-color-v2,.0333); border-color: $primary-color-v2;}
  .ccol-3 & { background-color: rgba($primary-color-v3,.0333); border-color: $primary-color-v3;}
  .ccol-4 & { background-color: rgba($primary-color-v4,.0333); border-color: $primary-color-v4;}

  > p {
    margin-bottom: 0;
  }
}

.button {
  font-family: $sans-font-family;
  text-transform: uppercase;
  font-weight: $global-weight-bold;
  color:rgba($white,.95) !important;
  background-color: $button-background;
  .ccol-1 & { background-color: $primary-color-v1;}
  .ccol-2 & { background-color: $primary-color-v2;}
  .ccol-3 & { background-color: $primary-color-v3;}
  .ccol-4 & { background-color: $primary-color-v4;}

  &:hover, &:focus {
    background-color: scale-color($button-background, $lightness: -15%);
    .ccol-1 & { background-color: scale-color($primary-color-v1, $lightness: -15%);}
    .ccol-2 & { background-color: scale-color($primary-color-v2, $lightness: -15%);}
    .ccol-3 & { background-color: scale-color($primary-color-v3, $lightness: -15%);}
    .ccol-4 & { background-color: scale-color($primary-color-v4, $lightness: -15%);}
  }



  &.hollow {
    border-width:3px;
  }

  .bginverse & {
    background-color: rgba($white,.9);
    color:$primary-color !important;
    .ccol-1 & { color: $primary-color-v1 !important;}
    .ccol-2 & { color: $primary-color-v2 !important;}
    .ccol-3 & { color: $primary-color-v3 !important;}
    .ccol-4 & { color: $primary-color-v4 !important;}
    &:hover, &:focus {
      background-color: $white;
    }
  }
}

main[role="main"] {
  li {
    margin-bottom:$paragraph-margin-bottom/2;
  }
}

