.hero {
  background-color: $body-font-color;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color:$white;
  .page__subtitle {
    color:$white !important;
  }
}

.hero__content {
  padding:4rem 0;
}

